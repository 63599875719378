import {
  AbsoluteCenter,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React from 'react';
import { CardGrid, cardGridMaxW } from '../components/CardGrid';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { seo } from '../components/Seo';

interface ListEntry {
  heading: string;
  body: string;
}
interface Props {
  data: {
    homeJson: {
      advantages: ListEntry[];
      whoAreWeFor: string[];
      easySteps: ListEntry[];
    };
  };
}

export const Head = seo({ title: 'Home' });

export default function IndexPage({
  data: {
    homeJson: { advantages, whoAreWeFor, easySteps },
  },
}: Props) {
  return (
    <Page>
      <PageHeader title="Industrial Workers of the World" subtitle="Nga Kaimahi Ahumahi o te Ao" />

      <Center>
        <VStack>
          <Heading size="md">Who is the IWW for?</Heading>
          <UnorderedList>
            {whoAreWeFor.map((item: string) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </UnorderedList>
        </VStack>
      </Center>

      <Heading>Advantages</Heading>
      <CardGrid items={advantages}>
        <Card maxW={cardGridMaxW}>
          <CardBody fontSize="xl">
            <Center>
              <AbsoluteCenter axis="vertical">
                <Link href="/about">Read more about the IWW</Link>
              </AbsoluteCenter>
            </Center>
          </CardBody>
        </Card>
      </CardGrid>

      <Center>
        <VStack>
          <Heading size="md">In {easySteps.length} Easy Steps</Heading>
          {easySteps.map((item: ListEntry, index: number) => (
            <Card key={item.heading} direction="row">
              <Flex alignItems="center">
                <Text fontSize="2xl" paddingLeft={6} paddingRight={4}>
                  {index + 1}
                </Text>
              </Flex>
              <CardBody>
                <Heading size="md">{item.heading}</Heading>
                <Text>{item.body}</Text>
              </CardBody>
            </Card>
          ))}
        </VStack>
      </Center>
    </Page>
  );
}

export const pageQuery = graphql`
  query HomePageTemplate {
    homeJson {
      advantages {
        heading
        body
      }
      whoAreWeFor
      easySteps {
        heading
        body
      }
    }
  }
`;
